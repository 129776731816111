import { EditableInventoryInterface, InventoryInterface, SidePanelInterface } from "../types";
import SidePanel from "./SidePanel";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import moment, { Moment } from "moment";
import ProductStockHistoryList from "./ProductStockHistoryList";
import { Label, InputField, ModalTitle, FormGroup } from "./SharedComponents";
import CustomDatePicker from "./CustomDatePicker"; // Import CustomDatePicker
import apiClient from "../services/apiClient";

interface Props extends Omit<SidePanelInterface, 'children'> {
    product?: InventoryInterface | null
};

const fieldRowStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 768px)': {
        flexDirection: 'column'
    }
}


const RecordStockEntryForm = ({ product, handleHidePanel }: ({ product?: InventoryInterface | null } & Omit<SidePanelInterface, 'isVisible' | 'children'>)) => {
    const [batchNumber, setBatchNumber] = useState<string>('');
    const [quantity, setQuantity] = useState<number>(0);
    const [expirationDate, setExpirationDate] = useState<Moment | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        if (name === 'batchNumber') {
            setBatchNumber(value);
        } else if (name === 'quantity') {
            setQuantity(Math.abs(Number(value)));
        }
    }

    const handleDateChange = (date: Moment | null) => {
        setExpirationDate(date);
    };

    const isFieldsValid = () => {
        if (batchNumber.length === 0) {
            alert("Batch number is required");
            return false;
        }
        if (quantity === 0) {
            alert("Quantity is required");
            return false;
        }
        return true;
    }

    const handleSave = () => {
        if (!isFieldsValid()) return;

        setIsLoading(true);

        const batchJson: EditableInventoryInterface = {
            quantity: quantity,
            product_id: product?.product_id,
        }

        if (batchNumber.length > 0) {
            batchJson.batch_no = batchNumber;
        }

        if (expirationDate) {
            batchJson.expiration_date = expirationDate.format('YYYY-MM-DD');
        }

        console.log("batchJson", batchJson);

        recordStockEntry(batchJson).then(() => {
            handleHidePanel(batchJson);
        }).catch((error) => {
            console.log("Error recording entry", error);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const recordStockEntry = (inventoryEntry: EditableInventoryInterface) => {
        return apiClient.post("/inventory/record-stock-entry", inventoryEntry);
    }

    return (
        <>
            <div>Record Stock Entry</div>
            <Box sx={fieldRowStyle}>
                <FormGroup style={{ marginRight: '20px' }}>
                    <Label>Batch #:</Label>
                    <InputField type="text" name="batchNumber" value={batchNumber} onChange={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label>Quantity</Label>
                    <InputField type="number" name="quantity" value={quantity} onChange={handleChange} />
                </FormGroup>
            </Box>
            <FormGroup>
                <Label>Expiration Date:</Label>
                <CustomDatePicker
                    label="Expiration Date"
                    value={expirationDate}
                    onChange={handleDateChange}
                />
            </FormGroup>
            <Button onClick={handleSave}>{isLoading ? <CircularProgress size="15" color="inherit" /> : "Save"}</Button>
        </>
    )
}

export default function ProductInventoryModal(props: Props) {
    const { product, handleHidePanel, ...sidePanelProps } = props;

    const hidePanel = (inventoryEntry: EditableInventoryInterface | null = null) => {
        if (inventoryEntry) {
            handleHidePanel(inventoryEntry);
        } else {
            handleHidePanel();
        }
    }

    return (
        <SidePanel {...sidePanelProps} handleHidePanel={() => hidePanel()}>
            <ModalTitle>Product Stock Management</ModalTitle>
            {product && (
                <div style={{ marginBottom: '50px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                        <h3 style={{ margin: '0px' }}>{product.product_name}</h3>
                        <span style={{ fontSize: '12px', position: 'relative', top: '-4px', marginLeft: '3px' }}>({product.total_quantity} in stock)</span>
                    </div>
                    <p style={{ fontSize: '12px', margin: '0px', position: 'relative', top: '-2px', marginBottom: '0px' }}>Last Stocked on {moment(product.last_restocked_date).format('YYYY-MM-DD')}</p>
                </div>
            )}
            <ProductStockHistoryList product={product} />
            <hr style={{ margin: '50px 0px', backgroundColor: '#f3f3f3', border: 'none', height: '1px' }} />
            <RecordStockEntryForm product={product} handleHidePanel={hidePanel} />
        </SidePanel>
    )
}
