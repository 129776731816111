import React, { useEffect, useState } from 'react';
import { Button, FormGroup, InputField, Label, ModalTitle, Select } from '../SharedComponents';
import { Box, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components';

import { OrderInterface, OrderProductInterface } from '../../types';
import apiClient from '../../services/apiClient';

interface EditOrderFormProps {
	order: any;
	products: any;
	isOrderProductsLoading: boolean;
	cancelEdit: () => void;
	handleHidePanel: (order?: OrderInterface) => void;
}

const productItemWrapper = {
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	'@media (max-width: 768px)': {
		flexWrap: 'wrap',
		padding: '10px',
		border: '1px solid #c4c4c4',
		borderRadius: '8px',
		marginBottom: '10px',
		boxShadow: '0px 3px 4px 0px rgb(221 232 235 / 61%)',

		// flexDirection: 'column'
	}
}

const productSelectStyle = {
	marginRight: '20px',
	maxWidth: '55%',
	'@media (max-width: 768px)': {
		maxWidth: '100%',
	}
}

const productInputWrapperStyle = {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	'@media (max-width: 768px)': {
		width: '100%'
	}
}

const productUnitPriceWrapper = {
	marginRight: '20px',
	maxWidth: '15%',
	'@media (max-width: 768px)': {
		maxWidth: '50%',
	}
}

const productQuantityWrapper = {
	maxWidth: '15%',
	'@media (max-width: 768px)': {
		maxWidth: '25%',
	}
}

const fieldRowStyle = {
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	'@media (max-width: 768px)': {
		flexWrap: 'wrap',
		flexDirection: 'column'
	}
}



const AddProductButton = styled.div`
    padding: 10px;
    width: 100%;
    border-radius: 8px;
    border: 1px dashed black;
    color: black;
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
    box-sizing: border-box;
`;

const fetchAllInventory = () => {
	return apiClient.get('/inventory');
};

const submitOrder = (orderData: OrderInterface) => {
	const isEditing = orderData.id ? true : false;

	if (isEditing) {
		return apiClient.update(`/orders/${orderData.id}`, orderData);
	}

	return apiClient.post('/orders', orderData);

};

interface EditOrderModalProductsListInterface {
	products: OrderProductInterface[];
	handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number, inventoryList?: any[]) => void;
	addProductSlot: (a: number, b: number) => void;
	removeProductSlot: (index: number) => void;
}

const EditOrderModalProductsList = ({ products: orderProducts, handleChange, addProductSlot, removeProductSlot }: EditOrderModalProductsListInterface) => {
	const [inventoryList, setInventoryList] = useState<any[]>([]);
	const [isFetchingInventory, setIsFetchingInventory] = useState<boolean>(false);

	useEffect(() => {
		setIsFetchingInventory(true);
		fetchAllInventory().then((response: any) => {
			console.log("response", response);
			let inventoryList = response.data ? response.data.filter((inventoryItem: any) => inventoryItem.total_quantity > 0) : [];
			setInventoryList(inventoryList);
			setIsFetchingInventory(false);
		});
	}, []);

	if (isFetchingInventory) {
		return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '20px 0px' }}><CircularProgress size={30} /></div>;
	}

	return (
		<>
			{orderProducts && orderProducts.map((orderProduct: OrderProductInterface, index: number) => {
				return (
					<Box sx={productItemWrapper} style={{ alignItems: 'center', width: '100%' }} key={index}>
						<FormGroup sx={productSelectStyle}>
							{index === 0 && <Label>Product</Label>}
							<Select name="id" value={orderProduct.id} onChange={(e) => handleChange(e, index, inventoryList)} style={{ padding: '15px' }}>
								{inventoryList.map((inventoryListItem: any, inventoryItemIdx: number) => (
									<option disabled={inventoryListItem.total_quantity === 0} value={inventoryListItem.product_id} key={inventoryListItem.id}>{inventoryListItem.product_name} (x{inventoryListItem.total_quantity ?? 0})</option>
								))}
							</Select>
						</FormGroup>
						<Box sx={productInputWrapperStyle}>
							<FormGroup sx={productUnitPriceWrapper}>
								{index === 0 && <Label>Unit Price</Label>}
								<InputField style={{ padding: '15px' }} type="number" name="unit_price_at_purchase" value={orderProduct.unit_price_at_purchase} onChange={(e) => handleChange(e, index)} />
							</FormGroup>
							<FormGroup sx={productQuantityWrapper}>
								{index === 0 && <Label>Quantity</Label>}
								<InputField min="1" type="number" name="quantity" value={orderProduct.quantity} style={{ textAlign: 'center', padding: '15px' }} onChange={(e) => handleChange(e, index)} />
							</FormGroup>
							<div onClick={() => removeProductSlot(index)} style={{ marginLeft: '10px', marginTop: '20px', maxWidth: '15%', cursor: 'pointer' }}><DeleteIcon /></div>
						</Box>
					</Box>
				);
			})}
			<AddProductButton onClick={() => addProductSlot(Number(inventoryList[0].price), Number(inventoryList[0].product_id))}>Add New</AddProductButton>
		</>
	);
};

const EditOrderForm = ({ order: initialOrder, products: initialProducts, isOrderProductsLoading, cancelEdit, handleHidePanel }: EditOrderFormProps) => {
	const [order, setOrder] = useState<OrderInterface>(initialOrder);
	const [orderProducts, setOrderProducts] = useState<OrderProductInterface[]>(initialProducts);
	const [isSubmittingOrder, setIsSubmittingOrder] = useState<boolean>(false);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setOrder({ ...order, [name]: value });
	};

	const handleOrderProductsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number, inventoryList: any[] = []) => {
		const { name } = e.target;
		let value = Number(e.target.value);
		let newOrderProducts: OrderProductInterface[] = [...orderProducts];
		newOrderProducts[index] = {
			...newOrderProducts[index],
			[name]: value
		};

		if (name === 'id' && e.target instanceof HTMLSelectElement) {
			const selectedIndex = e.target.selectedIndex;

			newOrderProducts[index] = {
				...newOrderProducts[index],
				unit_price_at_purchase: inventoryList[selectedIndex].price,
			};

		}
		setOrderProducts(newOrderProducts);
	};

	useEffect(() => {
		console.log("order changed", order);
	}, [order]);

	useEffect(() => {
		console.log("products changed", orderProducts);
	}, [orderProducts]);

	useEffect(() => {
		setOrder(initialOrder);
	}, [initialOrder]);

	useEffect(() => {
		setOrderProducts(initialProducts);
	}, [initialProducts]);

	const handleSave = () => {
		setIsSubmittingOrder(true);

		let orderData = {
			...order,
			products: orderProducts
		};
		console.log("orderData", orderData);

		submitOrder(orderData).then((response: any) => {
			handleHidePanel(response.data);
		}).catch((error: any) => {
			console.error("error submitting order", error);
		}).finally(() => {
			setIsSubmittingOrder(false);
		})
	};

	const addProductSlot = (defaultPrice: number, defaultId: number) => {
		setOrderProducts([...orderProducts, {
			id: defaultId,
			unit_price_at_purchase: defaultPrice,
			quantity: 1
		}]);
	};

	const removeProductSlot = (index: number) => {
		let newOrderProducts = [...orderProducts];
		newOrderProducts.splice(index, 1);
		setOrderProducts(newOrderProducts);
	};

	return (
		<>
			<ModalTitle>Edit Order</ModalTitle>
			<Box sx={fieldRowStyle}>
				<FormGroup style={{ marginRight: '20px' }}>
					<Label>Ordered By</Label>
					<InputField type="text" name="customer_name" value={order.customer_name} onChange={handleChange} />
				</FormGroup>
				<FormGroup>
					<Label>Delivery Address</Label>
					<InputField type="text" name="customer_address" value={order.customer_address} onChange={handleChange} />
				</FormGroup>
			</Box>
			<Box sx={fieldRowStyle}>
				<FormGroup style={{ marginRight: '20px' }}>
					<Label>Phone</Label>
					<InputField type="text" name="customer_phone" value={order.customer_phone} onChange={handleChange} />
				</FormGroup>
				<FormGroup>
					<Label>Email</Label>
					<InputField type="text" name="customer_email" value={order.customer_email} onChange={handleChange} />
				</FormGroup>
			</Box>
			<hr style={{ margin: '50px 0px 30px 0px', backgroundColor: '#f3f3f3', border: 'none', height: '1px' }} />
			<div>
				<h4>Products</h4>
				{isOrderProductsLoading ? (
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '50px 0px' }}>
						<CircularProgress size={30} color="inherit" />
					</div>
				) : (
					<EditOrderModalProductsList
						products={orderProducts}
						handleChange={handleOrderProductsChange}
						addProductSlot={addProductSlot}
						removeProductSlot={removeProductSlot}
					/>
				)}
			</div>
			<Box sx={fieldRowStyle}>
				<Button style={{ marginRight: '20px', flex: 2 }} onClick={handleSave}>
					{isSubmittingOrder ? <CircularProgress size={15} color="inherit" /> : 'Save'}
				</Button>
				{order.id && <Button style={{ flex: 1 }} onClick={cancelEdit}>Cancel</Button>}
			</Box>
		</>
	);
};

export default EditOrderForm;
