import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import apiClient from '../services/apiClient';
import { useSelector } from 'react-redux';
import { RootState } from '../types';
import Header from '../components/Header';

const Wrapper = styled.div`
background-color:#f3f4f8;
width:100%;
min-height:100vh;
padding:20px 40px;
box-sizing:border-box;
@media (max-width: 768px) {
    padding: 20px 20px;
    padding-top:0px
}
`;
const AnalyticsCardsContainer = styled.div`
display: flex;
flex-direction:row;
flex-wrap:wrap;
`

const AnalyticsCardWrapper = styled.div`
padding:10px;
@media (max-width: 768px) {
    width: 100%;
}
`

const AnalyticsCard = styled.div`
background-color: white;
padding: 30px 40px;
border-radius: 10px;
height:128px;
box-sizing:border-box;
`
const AnalyticsCardLabel = styled.p`
color: #666;
font-size: 14px;
margin:0px;
margin-bottom: 10px;
`

const AnalyticsCardValue = styled.h2<{ size?: 'small' | 'medium' | 'large' }>`
margin: 0px;
font-size: ${(props) => {
        switch (props.size) {
            case 'small':
                return '20px';
            case 'medium':
                return '35px';
            case 'large':
                return '50px';
            default:
                return '35px'; // default to medium size if no size is provided
        }
    }};
`;

interface Stats {
    total_sales: number;
    total_expenses: number;
    total_products_sold: number;
    stock_qty_available: number;
    top_selling_product: string;
}


export default function Dashboard() {
    const [stats, setStats] = useState<Stats | null>(null);

    const user = useSelector((state: RootState) => state.auth.user);

    useEffect(() => {
        apiClient.get('/stats').then((response: any) => {
            console.log("fetched stats", response.data);
            setStats(response.data);
        }).catch((error) => {
            console.log("error fetching stats", error);
        })
    }, []);

    if (!stats) {
        return null;
    }

    return (
        <Wrapper>
            <div style={{ padding: '20px' }}>
                <Header
                    title="Dashboard"
                    description="View stats and analytics"
                />
            </div>
            <AnalyticsCardsContainer>
                <AnalyticsCardWrapper>
                    <AnalyticsCard>
                        <AnalyticsCardLabel>Total Sales</AnalyticsCardLabel>
                        <AnalyticsCardValue>GH&cent;{stats?.total_sales.toLocaleString()}</AnalyticsCardValue>
                    </AnalyticsCard>
                </AnalyticsCardWrapper>
                <AnalyticsCardWrapper>
                    <AnalyticsCard>
                        <AnalyticsCardLabel>Total Expenses</AnalyticsCardLabel>
                        <AnalyticsCardValue>GH&cent;{stats?.total_expenses.toLocaleString()}</AnalyticsCardValue>
                    </AnalyticsCard>
                </AnalyticsCardWrapper>
                <AnalyticsCardWrapper>
                    <AnalyticsCard>
                        <AnalyticsCardLabel>Total Products Sold</AnalyticsCardLabel>
                        <AnalyticsCardValue>{stats?.total_products_sold.toLocaleString()}</AnalyticsCardValue>
                    </AnalyticsCard>
                </AnalyticsCardWrapper>
                <AnalyticsCardWrapper>
                    <AnalyticsCard>
                        <AnalyticsCardLabel>Available Stock Qty</AnalyticsCardLabel>
                        <AnalyticsCardValue>{stats?.stock_qty_available.toLocaleString()}</AnalyticsCardValue>
                    </AnalyticsCard>
                </AnalyticsCardWrapper>
                <AnalyticsCardWrapper>
                    <AnalyticsCard>
                        <AnalyticsCardLabel>Top Product</AnalyticsCardLabel>
                        <AnalyticsCardValue size="small">{stats?.top_selling_product}</AnalyticsCardValue>
                    </AnalyticsCard>
                </AnalyticsCardWrapper>

            </AnalyticsCardsContainer>
        </Wrapper>
    )
}